import { IRoomResource } from 'kits/apiKit3/legacy';
import { sendEvent } from 'kits/eventKit';
import IvicosStrings from 'kits/language/stringKit';
import { useNavigate } from 'react-router';
import { TEventHandler, TUpdateEvent, TUserListFromServer } from 'services/socket-connection/types';
import { useRoomList } from 'shared-state/directory/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { useDirectMessageUserList, useUserList, useVisitorList } from 'shared-state/presence/hooks';
import utf8 from 'utf8';
import {
    SERVER_EMIT_CROSS_AREA_MESSAGE_USER_LIST,
    SERVER_EMIT_HOST_USER_AT_VISITOR_ENTRANCE,
    SERVER_EMIT_KICK_VISITOR,
    SERVER_EMIT_MESSAGE_EVENT,
    SERVER_EMIT_ROOM_EVENT,
    SERVER_EMIT_ROOM_SESSION_TOKEN,
    SERVER_EMIT_USER_AT_VISITOR_ENTRANCE,
    SERVER_EMIT_USER_JOINED,
    SERVER_EMIT_USER_JOINED_AN_AREA,
    SERVER_EMIT_USER_LEFT_OR_LOGGED_OUT,
    SERVER_EMIT_VISITOR_JOINED
} from '../../events';
import { useJitsiSessionIdHandler } from '../../hooks';

const useUserListUpdateHandler = () => {
    const [, setUserList] = useUserList();
    const localProfile = useLocalProfile();
    const filterUserListFromServer = (userListFromServer: TUserListFromServer[]) => {
        const lastAreaId = localStorage.getItem('lastAreaId');
        const localID = localStorage.getItem('localID');
        return userListFromServer.filter(({ uid, userInThisArea }) => uid !== localID && userInThisArea === lastAreaId);
    };

    const handleUserListUpdate = (userListFromServer: TUserListFromServer[]) => {
        const excludeUsersFromOtherCampuses = userListFromServer.filter((user) => user.campusId === localProfile?.campusId);
        console.log('connected to the websocket server 🎉', excludeUsersFromOtherCampuses);
        const filteredList = filterUserListFromServer(excludeUsersFromOtherCampuses);
        setUserList(filteredList);
    };
    return handleUserListUpdate;
};

const useRoomListUpdateHandler = () => {
    const [, setRoomList] = useRoomList();
    const handleRoomListUpdate = (roomDataFromServer: TUpdateEvent) => {
        // console.log('room update', roomDataFromServer);
        const { type, room, roomId } = roomDataFromServer;

        if (type == 'update' && room) {
            setRoomList((prevList: IRoomResource[]) => {
                return prevList.map((r) => {
                    if (r.id != room?.id) return r;
                    // console.log('Found room, replacing...');
                    return room;
                });
            });
        }

        if (type == 'creation' && room) {
            setRoomList((prevList: IRoomResource[]) => {
                if (!room) return prevList;
                return [...prevList, ...[room]];
            });
        }

        setRoomList((prevList: IRoomResource[]) => prevList.filter((r) => r.id != roomId));
    };

    return handleRoomListUpdate;
};

const useMessageHandler = () => {
    const { handleSessionIdRequest, handleSessionIdResponse } = useJitsiSessionIdHandler();
    const [, addMessage] = useReceivedMessageInbox();
    const handleMessage = (data: any) => {
        const { type, sender, meta, body, announce } = data;
        const newReceivedMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: sender.uid,
            type: type,
            timestamp: new Date(),
            meta: {
                forRoom: meta?.forRoom,
                forUser: meta?.forUser,
                attachedRoom: meta?.attachedRoom
            },
            body: body,
            announce: announce,
            timeout: undefined,
            identityValue:
                type === 'knock'
                    ? meta?.forRoom
                        ? meta?.forRoom + '-' + sender.uid.split('-')[0]
                        : undefined
                    : meta?.attachedRoom
                    ? meta?.attachedRoom + '-' + sender.uid.split('-')[0]
                    : undefined
        };

        const crossAreaInviteMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: sender.uid,
            type: type,
            timestamp: new Date(),
            meta: {
                forRoom: meta?.forRoom,
                forUser: meta?.forUser,
                attachedRoom: meta?.attachedRoom
            },
            body: body,
            room: data.room,
            announce: announce,
            timeout: undefined,
            identityValue: meta?.attachedRoom + '-' + sender.uid.split('-')[0]
        };

        if (type === 'cross-area-invite') {
            addMessage(crossAreaInviteMessage);
        } else if (
            type != 'request-jitsisessionid' &&
            type != 'response-jitsisessionid' &&
            type !== 'round-table-update' &&
            type !== 'room-attachments-updated' &&
            type !== 'cross-area-invite'
        ) {
            addMessage(newReceivedMessage);
        } else if (type == 'request-jitsisessionid') {
            handleSessionIdRequest(sender.uid, body);
        } else if (type == 'response-jitsisessionid') {
            handleSessionIdResponse(body);
        } else if (type === 'round-table-update') {
            sendEvent('round-table-update', body);
        } else if (type === 'room-attachments-updated') {
            sendEvent('room-attachments-updated', {});
        }
    };

    return handleMessage;
};

const useVisitorJoinedHandler = () => {
    const localProfile = useLocalProfile();
    const [, addMessage] = useReceivedMessageInbox();
    const [, setVisitorList] = useVisitorList();

    const handleVisitorJoined = (data: any) => {
        console.log('visitor connected to the websocket server 🎉', data);

        const { visitor, hostsAtVisitorEntrance } = data ?? {};
        const { displayName, host, subInfo } = visitor ?? {};

        if (localProfile?.uid && host && host.includes(localProfile?.uid) && !window.location.pathname.includes(`/meet/visitor-${subInfo.split(':')[2]}`))
            addMessage({
                type: 'guest',
                body:
                    hostsAtVisitorEntrance.length === 1
                        ? hostsAtVisitorEntrance[0].displayName + ' ' + IvicosStrings.visitorIsWith + ' ' + utf8.decode(displayName)
                        : hostsAtVisitorEntrance.length > 1
                        ? hostsAtVisitorEntrance[0].displayName + ' ' + IvicosStrings.visitorWithOthers + ' ' + utf8.decode(displayName)
                        : utf8.decode(displayName) + ' ' + IvicosStrings.visitorIsWaitingNotificationTitle,
                timestamp: new Date(),
                id: makeMessageId(),
                sender: 'system',
                meta: { attachedRoom: 'visitor-' + subInfo.split(':')[2] },
                announce: hostsAtVisitorEntrance.length > 0 ? false : window.location.pathname.includes('/meet') ? false : true,
                hostsOfGuest: hostsAtVisitorEntrance.map((host) => ({
                    uid: host.uid,
                    name: host.displayName,
                    image: host.profileImage
                })),
                identityValue: 'visitor-' + subInfo.split(':')[2],
                dismissed: false
            });

        handleListUpdate(setVisitorList, visitor);
    };
    return handleVisitorJoined;
};

const useVisitorKickedOutHandler = () => {
    const localProfile = useLocalProfile();
    const routeHistory = useNavigate();

    const handleVisitorKickedOut = (data: string) => {
        if (data === 'kick-visitor' && localProfile?.type.includes('visitor')) routeHistory('/e/' + localProfile?.invitationId);
    };

    return handleVisitorKickedOut;
};

const useVisitorListHandler = () => {
    const [, setVisitorList] = useVisitorList();
    const handleVisitorLists = (data: any) => {
        handleListUpdate(setVisitorList, data);
    };

    return handleVisitorLists;
};

const useUserListForUserPresentAtVisitorEntranceHandler = () => {
    const [userList, setUserList] = useUserList();
    const handleUserListForUserPresentAtVisitorEntrance = (data: any) => {
        const excludeUserPresentAtVisitorEntrance = userList.filter((user) => user.uid !== data.uid);
        setUserList(excludeUserPresentAtVisitorEntrance);
    };

    return handleUserListForUserPresentAtVisitorEntrance;
};

const useCrossAreaMessageUserListsHandler = () => {
    const localProfile = useLocalProfile();
    const [, setDirectMessageUserList] = useDirectMessageUserList();
    const handleCrossAreaMessageUserLists = (data: any) => {
        const excludeUsersFromOtherCampuses = data.filter((user) => user.campusId === localProfile?.campusId);
        setDirectMessageUserList(excludeUsersFromOtherCampuses);
    };

    return handleCrossAreaMessageUserLists;
};

const updateList = (prevList: any, data: any) => {
    const existingItem = prevList.find((item: any) => item.uid === data.uid);
    if (existingItem) {
        return prevList;
    } else {
        return [...prevList, data];
    }
};

const handleListUpdate = (setList: any, data: any) => {
    setList((prevList: any) => updateList(prevList, data));
};

const useRoomSessionTokenHandler = () => {
    const routeHistory = useNavigate();
    const handleRoomSessionToken = (data: any) => {
        const { areaId, roomId, sessionToken } = data;
        sessionStorage.setItem('tempRoomSessionToken', sessionToken);
        if (sessionToken) {
            const roomPath = `/areas/${areaId}/rooms/${roomId}`;
            routeHistory(roomPath);
        }
    };

    return handleRoomSessionToken;
};

const useMessageAtVisitorEntranceHandler = () => {
    const [, addMessage] = useReceivedMessageInbox();
    const handleMessageAtVisitorEntrance = (data: any) => {
        console.log('🚀 ~ handleMessageAtVisitorEntrance ~ data:', data);
        const { type, sender, body, announce } = data;
        const newReceivedMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: sender.uid,
            type: type,
            timestamp: new Date(),
            meta: {},
            body: body,
            announce: announce,
            timeout: undefined,
            senderInfo: sender
        };
        console.log('🚀 ~ file: users.tsx:251 ~ handleMessageAtVisitorEntrance ~ data:', data, newReceivedMessage);
        addMessage(newReceivedMessage);
    };

    return handleMessageAtVisitorEntrance;
};

// const useUserProfileHandler = () => {
//     const handleUserProfile = (data: any) => {
//         console.log('🚀 ~ file: users.tsx:164 ~ handleUserProfile ~ data:', data);
//     };

//     return handleUserProfile;
// };

export const useAllHandlers = (): TEventHandler[] => {
    const handleUserListUpdate = useUserListUpdateHandler();
    const handleRoomListUpdate = useRoomListUpdateHandler();
    const handleMessage = useMessageHandler();
    const handleVisitorJoined = useVisitorJoinedHandler();
    const handleVisitorKickedOut = useVisitorKickedOutHandler();
    const handleVisitorLists = useVisitorListHandler();
    const handleUserListForUserPresentAtVisitorEntrance = useUserListForUserPresentAtVisitorEntranceHandler();
    const handleCrossAreaMessageUserLists = useCrossAreaMessageUserListsHandler();
    const handleRoomSessionToken = useRoomSessionTokenHandler();
    const handleMessageAtVisitorEntrance = useMessageAtVisitorEntranceHandler();
    // const handleUserProfile = useUserProfileHandler();

    return [
        {
            eventName: SERVER_EMIT_USER_JOINED,
            callback: handleUserListUpdate
        },
        {
            eventName: SERVER_EMIT_USER_JOINED_AN_AREA,
            callback: handleUserListUpdate
        },
        {
            eventName: SERVER_EMIT_USER_LEFT_OR_LOGGED_OUT,
            callback: handleUserListUpdate
        },
        {
            eventName: SERVER_EMIT_ROOM_EVENT,
            callback: handleRoomListUpdate
        },
        {
            eventName: SERVER_EMIT_MESSAGE_EVENT,
            callback: handleMessage
        },
        {
            eventName: SERVER_EMIT_KICK_VISITOR,
            callback: handleVisitorKickedOut
        },
        {
            eventName: SERVER_EMIT_VISITOR_JOINED,
            callback: handleVisitorJoined
        },
        {
            eventName: SERVER_EMIT_USER_AT_VISITOR_ENTRANCE,
            callback: handleVisitorLists
        },
        {
            eventName: SERVER_EMIT_HOST_USER_AT_VISITOR_ENTRANCE,
            callback: handleUserListForUserPresentAtVisitorEntrance
        },
        {
            eventName: SERVER_EMIT_CROSS_AREA_MESSAGE_USER_LIST,
            callback: handleCrossAreaMessageUserLists
        },
        {
            eventName: SERVER_EMIT_ROOM_SESSION_TOKEN,
            callback: handleRoomSessionToken
        },
        {
            eventName: 'server-emit-message-at-visitor-entrance',
            callback: handleMessageAtVisitorEntrance
        }
        // {
        //     eventName: SERVER_EMIT_USER_PROFILE,
        //     callback: handleUserProfile
        // }
    ];
};
