import { DefaultButton, Stack, Text } from '@fluentui/react';
import 'App.css';
import WarningNotSupportedBrowser from 'components/WarningNotSupportedBrowser/WarningNotSupportedBrowser';
import TopBar from 'features/Header/children/TopBar/TopBar';
import IconKit from 'kits/IconKit';
import IvicosStrings from 'kits/language/stringKit';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Core from 'routes/Core/Core';
import Dashboard from 'routes/Dashboard/Dashboard';
import Flags, { getFlag, setFlag } from 'routes/Flags/Flags';
import DirectedToWelcome from 'routes/helpers/DirectedToWelcome';
import DirectedUserToDashboard from 'routes/helpers/DtrectedUserToDashboard';
import ProtectedRoute from 'routes/helpers/ProtectedRoute';
import HostPerspectiveWaitingArea from 'routes/HostPerspectiveWaitingArea/HostPerspectiveWaitingArea';
import InsertedInvalidLink from 'routes/InsertedInvalidLink/InsertedInvalidLink';
import Login from 'routes/Login';
import Main from 'routes/Main/Main';
import Mobile from 'routes/Mobile/Mobile';
import ResetWizard from 'routes/ResetWizard/ResetWizard';
import { UITest } from 'routes/UITest';
import VisitorsExit from 'routes/VisitorsExit/VisitorsExit';
import WaitingArea from 'routes/WaitingArea/WaitingArea';
import { idpService } from 'services/api/identity-provider.service';
import { LanguageProvider } from '../src/components/context/LanguageContext';
import NewUserAwaitingConfirmation from 'routes/NewUserAwaitingConfirmation';
import NewUserSomethingWrong from 'routes/NewUserSomethingWrong';
import MagicLinkVerification from 'routes/MagicLinkVerification';
import Profile from 'routes/Profile';
import IvCAMPUSSetup from 'routes/IvCAMPUSSetup';
import SetupComplete from 'routes/SetupComplete';
import FindCampus from 'routes/FindCampus/FindCampus';
import { UserDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import CreateNewUserAccount from 'routes/CreateNewUserAccount';
import AccountCreationSuccessful from 'routes/AccountCreationSuccessful';
import AccountCreationSomethingWentWrong from 'routes/AccountCreationSomethingWentWrong';
import VisitorsEntry from 'routes/VisitorsEntry/VisitorsEntry';
import LandingPage from 'routes/LandingPage/LandingPage';

initializeIcons(/* optional base url */);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAppProps {
    children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App: React.FC<IAppProps> = ({ children }) => {
    const getUserLanguage = async () => {
        let response: UserDto | undefined;
        try {
            response = await idpService.getUser();
        } catch (error) {
            console.log('User not registered');
        }

        IvicosStrings.setLanguage(response?.preferred_language || (navigator.language === 'de' ? 'de' : 'en'));
        localStorage.setItem('chosenLanguage', response?.preferred_language || (navigator.language === 'de' ? 'de' : 'en'));
    };
    getUserLanguage();

    // if (getFlag('jitsiEnv') != 'staging' && getFlag('jitsiEnv') != 'production') setFlag('jitsiEnv', 'production');
    if (getFlag('jitsiEnv') != 'staging') setFlag('jitsiEnv', 'staging');

    const [isBrowserWarningShown, setIsBrowserWarningShown] = useState<boolean>(false);

    const showBrowserWarning = useCallback(async (userAgent: string) => {
        const isChrome = userAgent.includes('Chrome/') || userAgent.includes('CriOS/');

        const navVariable: any = window.navigator;

        const isBrave = navVariable.brave && (await navVariable.brave.isBrave());
        const isEdge = userAgent.includes('EdgiOS/') || userAgent.includes('EdgA/') || userAgent.includes('Edg/') || userAgent.includes('Edge/');

        const isSafari = userAgent.includes('Safari/') && !isChrome && !isEdge && !isBrave;

        if (getFlag('jitsiEnv') == 'production') {
            const isFirefox = userAgent.includes('Firefox/') || userAgent.includes('FxiOS/');
            setIsBrowserWarningShown(isSafari || isFirefox);
        } else {
            const isFirefox = userAgent.includes('Firefox/');
            const isFxiOS = userAgent.includes('FxiOS/');

            if (isFirefox) {
                const firefoxVersion = userAgent.match(/Firefox\/(\d+)/);
                if (firefoxVersion && parseInt(firefoxVersion[1], 10) < 120) {
                    setIsBrowserWarningShown(true);
                }
            } else if (isFxiOS) {
                const fxiOSVersion = userAgent.match(/FxiOS\/(\d+)/);
                if (fxiOSVersion && parseInt(fxiOSVersion[1], 10) < 120) {
                    setIsBrowserWarningShown(true);
                }
            } else setIsBrowserWarningShown(isSafari);
        }
    }, []);

    useEffect(() => {
        showBrowserWarning(navigator.userAgent);
    }, [showBrowserWarning]);

    const toDashboard = (
        <DirectedUserToDashboard>
            <Core>
                <Dashboard />
            </Core>
        </DirectedUserToDashboard>
    );
    const protectedToCore = (children: React.ReactNode) => {
        return (
            <ProtectedRoute>
                <Core>{children}</Core>
            </ProtectedRoute>
        );
    };
    const toWelcomeAndLanguage = (children: React.ReactNode) => {
        return (
            <DirectedToWelcome>
                <LanguageProvider>{children}</LanguageProvider>
            </DirectedToWelcome>
        );
    };
    const routeProtected = (children: React.ReactNode) => {
        return <ProtectedRoute>{children}</ProtectedRoute>;
    };

    return (
        <BrowserRouter>
            {isBrowserWarningShown ? (
                <WarningNotSupportedBrowser />
            ) : (
                <Routes>
                    {/* Login */}
                    <Route path="/login" element={toWelcomeAndLanguage(<Login />)} />
                    <Route path="/create-new-user-account" element={toWelcomeAndLanguage(<CreateNewUserAccount />)} />
                    <Route path="/accountCreationSuccessful" element={toWelcomeAndLanguage(<AccountCreationSuccessful />)} />
                    <Route path="/accountCreationSomethingWentWrong" element={toWelcomeAndLanguage(<AccountCreationSomethingWentWrong />)} />
                    <Route path="/newUserAwaitingConfirmation" element={toWelcomeAndLanguage(<NewUserAwaitingConfirmation />)} />
                    <Route path="newUserSomethingWrong" element={toWelcomeAndLanguage(<NewUserSomethingWrong />)} />
                    <Route
                        path="/magic-link-verification"
                        element={
                            <LanguageProvider>
                                <MagicLinkVerification />
                            </LanguageProvider>
                        }
                    />
                    <Route path="/profile" element={routeProtected(<Profile />)} />
                    <Route path="/ivcampussetup" element={routeProtected(<IvCAMPUSSetup />)} />
                    <Route path="/setupcomplete" element={routeProtected(<SetupComplete />)} />
                    <Route path="/welcome" element={routeProtected(<FindCampus />)} />

                    {/* Visitors */}
                    <Route path={'/i/:formattedOrgName/:customPath'} element={<VisitorsEntry />} />
                    <Route path={'/i/:invitationId'} element={<VisitorsEntry />} />
                    <Route path={'/visitorLandingPage/:formattedOrgName/:customPath'} element={<LandingPage />} />
                    <Route path={'/visitorLandingPage/:invitationId'} element={<LandingPage />} />
                    <Route path={'/w/:formattedOrgName/:customPath'} element={<WaitingArea />} />
                    <Route path={'/w/:invitationId'} element={<WaitingArea />} />
                    <Route path="/e/:invitationId" element={<VisitorsExit />} />
                    <Route path="/inserted-invalid-link" element={<InsertedInvalidLink />} />

                    <Route
                        path="/meet/:roomId"
                        element={
                            <Core>
                                <HostPerspectiveWaitingArea />
                            </Core>
                        }
                    />

                    {/* In case someone needs to clear their cache */}
                    <Route path="/reset" element={<ResetWizard />} />

                    {/* Flags */}
                    <Route path="/experimental" element={<Flags />} />

                    {/* Mobile Feature (Sketch) */}
                    <Route path="/mobile/:room/:jwt" element={<Mobile />} />

                    {/* UITesting */}
                    <Route path="/ui-test" element={routeProtected(<UITest />)} />

                    {/* Errors */}
                    <Route
                        path="/error/409"
                        element={<DisconnectScreen title={IvicosStrings.logInTwiceTitle} description={IvicosStrings.logInTwiceDescription} />}
                    />
                    <Route
                        path="/error/403"
                        element={
                            <DisconnectScreen
                                title={IvicosStrings.sessionExpiredTitle}
                                description={IvicosStrings.sessionExpiredDescription}
                                sessionExpiredReasons={[
                                    IvicosStrings.sessionExpiredReason1,
                                    IvicosStrings.sessionExpiredReason2,
                                    IvicosStrings.sessionExpiredReason3
                                ]}
                                sessionExpiredReasonsTitle={IvicosStrings.sessionExpiredReasonTitle}
                                clearSessionOnReconnect
                            />
                        }
                    />
                    <Route
                        path="/error/404"
                        element={
                            <DisconnectScreen
                                title={IvicosStrings.resourceNotFoundTitle}
                                description={IvicosStrings.resourceNotFoundDescription}
                                clearSessionOnReconnect
                            />
                        }
                    />
                    <Route
                        path="/error/500"
                        element={
                            <DisconnectScreen title={IvicosStrings.unexpectedErrorOccuredTitle} description={IvicosStrings.unexpectedErrorOccuredDescription} />
                        }
                    />
                    <Route path="/error" element={<DisconnectScreen />} />

                    {/* Portal */}
                    <Route path="/rooms" element={routeProtected(<Navigate to="/" />)} />
                    <Route path="/areas/:areaId/rooms/:roomId" element={protectedToCore(<Main />)} />
                    <Route path="/areas/:areaId" element={protectedToCore(<Main />)} />
                    <Route path="/areas" element={protectedToCore(<Dashboard />)} />
                    <Route path="/dashboard" element={protectedToCore(<Dashboard />)} />
                    <Route path="/dashboard/:tabKey">
                        <Route path="/dashboard/:tabKey" element={toDashboard} />
                        <Route path="/dashboard/:tabKey/:actionKey" element={toDashboard} />
                    </Route>
                    <Route path="/" element={protectedToCore(<Dashboard />)} />
                </Routes>
            )}
        </BrowserRouter>
    );
};

export default App;

interface IDisconnectScreenProps {
    title?: string;
    description?: string;
    clearSessionOnReconnect?: boolean;
    sessionExpiredReasons?: string[];
    sessionExpiredReasonsTitle?: string;
}

export const DisconnectScreen: React.FC<IDisconnectScreenProps> = ({
    title,
    description,
    clearSessionOnReconnect,
    sessionExpiredReasons,
    sessionExpiredReasonsTitle
}) => {
    return (
        <Stack style={{ height: '100vh', width: '100vw' }}>
            <TopBar title="Error" />
            <Stack grow horizontalAlign="center" verticalAlign="center">
                <Stack horizontal verticalAlign="center" style={{ marginBottom: 32, maxWidth: 560 }}>
                    <Stack.Item grow>
                        <IconKit.Icon icon={IconKit.IconSet.xLarge.Disconnected} size={128} style={{ marginRight: 32 }} />
                    </Stack.Item>
                    <Stack>
                        <Text variant="xxLargePlus" style={{ marginBottom: 8 }}>
                            {title || IvicosStrings.connectionLost}
                        </Text>
                        {!sessionExpiredReasons && (
                            <Text variant="large">
                                {description || (
                                    <>
                                        {IvicosStrings.clientConnectionLostDescription1} <br />
                                        {IvicosStrings.clientConnectionLostDescription2}
                                    </>
                                )}
                            </Text>
                        )}
                        {sessionExpiredReasons && sessionExpiredReasons.length > 0 && (
                            <>
                                <Text variant="large" style={{ marginTop: 16 }}>
                                    {sessionExpiredReasonsTitle}
                                </Text>
                                <ul style={{ marginTop: 8, paddingLeft: 20 }}>
                                    {sessionExpiredReasons.map((reason, index) => (
                                        <li key={index}>
                                            <Text variant="medium">{reason}</Text>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </Stack>
                </Stack>
                <Stack style={{ width: '100%', maxWidth: 560 }} horizontalAlign="end">
                    <DefaultButton
                        onClick={() => {
                            clearSessionOnReconnect && sessionStorage.clear();
                            clearSessionOnReconnect && localStorage.clear();
                            window.location.href = '/login';
                        }}
                    >
                        {IvicosStrings.sessionExpiredReconnect}
                    </DefaultButton>
                </Stack>
            </Stack>
        </Stack>
    );
};
