export const en = {
    //Topbar
    helpLinkTitle: 'Help',
    signOutActionTitle: 'Sign out',
    experimentalFeatures: 'Experimental features',
    leaveActionTitle: 'Leave Campus',
    leaveActionMessage: 'To leave Campus, simply close the tab in your browser. When you reopen the Campus web-page, you will return directly to your office.',
    logOutFromSystem: 'Click here',
    logOutFromSystemTwo: 'to log out.',

    // Identity Panel
    appearenceModification: 'Profile appearance',
    identityPanelTitle: 'My settings',
    statusTabTitle: 'Status',
    profileTabTitle: 'Profile',
    preferencesTabTitle: 'Preferences',
    switchOrgActionTitle: 'Switch organisation',
    updateImageActionTitle: 'Update image',
    changeEmailActionTitle: 'Change email',
    cameraPreferenceActionTitle: 'Disable camera when entering a room?',
    microphonePreferenceActionTitle: 'Disable microphone when entering a room?',
    cameraSettingErrorMessage:
        'An error occurred during the process of setting your device preferences. Please give it some time and try again. If the error repeats, contact help or the campus manager.',
    languageSelectionLabel: 'Select language',
    languageSelectionPlaceholder: 'Select a language',
    languageSelectionActionTitle: 'Language selection',
    languageSelectionDescription: 'CAMPUS will be displayed in German. Are you sure?',
    logoutTemporaryButton: 'Where to leave Campus',
    communicationSettings: 'Communication settings',

    // ProfileImageUploader page
    profileImageUploaderTitle: 'Upload image...',
    profileImageUploaderDescription: 'Drag and drop or click to select file.',
    profileImageUploaderDescription02: 'The file must not be larger than 2 MB.',
    profileImageUploaderUploadCapError: 'The size of the image you want to upload is larger than 2 megabytes.',

    // Help
    resetTutorialsActionTitle: 'Reset Tutorials',
    helpCentreActionTitle: 'Help Centre',
    reportErrorActionTitle: 'Report Error',
    dialogBoxTitle: 'Tutorials Reset',
    dialogBoxDescription: 'Please refresh the page to see the tutorials!',

    // General
    someone: 'Someone',
    dismissActionTitle: 'Dismiss',
    saveActionTitle: 'Save',
    saveChangesActionTitle: 'Save changes',
    savingActionLoadingTitle: 'Saving...',
    savingActionSuccessTitle: 'Saved!',
    changesSavedActionTitle: 'Changes saved',
    confirmActionTitle: 'Confirm',
    cancelActionTitle: 'Cancel',
    closeActionTitle: 'Close',
    refreshPageActionTitle: 'Refresh page',
    backgroundImageTitle: 'Background image',
    typeTitle: 'CAMPUS access for visitors',
    titleTitle: 'Title',
    calloutTitle: 'The title is visible at the visitor entrance',
    minimumCharacterLimitForTitle: 'The minimum character limit is 2',
    generalTitle: 'General',
    accessTitle: 'Access',
    editActionTitle: 'Edit',
    deleteActionTitle: 'Delete',
    clearActionTitle: 'Clear',
    submitActionTitle: 'Submit',
    meetActionTitle: 'Meet',
    finishActionTitle: 'Finish',
    okActionTitle: 'Ok',
    clearFilterActionTitle: 'Clear filter',
    reconnectToCampusActionTitle: 'Reconnect to CAMPUS',
    sendActionTitle: 'Send',
    skipActionTitle: 'Skip',
    skipActionTitleInCap: 'SKIP',
    startButtonLabelInCap: 'START',
    playActionTitle: 'Play',
    pauseActionTitle: 'Pause',
    downloadMicrosoftEdgeActionTitle: 'Download Microsoft Edge',
    enterActionTitle: 'Enter',
    talkingToMicrosoftActionTitle: 'Talking to Microsoft Identity Platform...',
    loadingTitle: 'Loading...',
    authenticatingTitle: 'Authenticating...',
    connectingTitle: 'Connecting...',
    loggedInto: 'You are currently logged into',
    yesAction: 'Yes',
    noAction: 'No',
    connectionLost: 'Connection lost',
    clientConnectionLostDescription1: 'The client lost connection to the server.',
    clientConnectionLostDescription2: 'This can have multiple reasons.',
    mobileActionTitle: 'Mobile',
    welcomeBack: 'Welcome back!',
    goodToSeeYouAgain: 'Good to see you again.',
    loadingText: 'Authenticating with ivicos identity services...',
    licenseNotActive: 'Your License does not seem to be activated.',
    errorAuthentication: 'There was an error with the authentication.',
    usersIn: 'Users in ',
    editMessageActionTitle: 'Edit message',
    previewActionTitle: 'Preview',
    addGifActionTitle: 'Add Gif',
    removeActionTitle: 'Remove',
    back: 'Back',
    of: 'of',
    discard: 'Discard',
    save: 'Save',
    cancel: 'Cancel',
    removeImage: 'Remove image',
    later: 'Later',
    writeUs: 'Write us',
    talkToSales: 'Talk to sales',
    benefitsMissing: 'Benefits you’ll miss…',

    // visitor landing page
    landingPageEnterName: 'Please enter your name here',
    landingPageEnterNameCallOutPart1: 'Please enter your name, a nickname, or pseudonym.',
    landingPageEnterNameCallOutPart2: 'This information will not be stored in our system and will be deleted as soon as you leave CAMPUS.',
    landingPagePrivacyHint: 'Privacy hints: click question-mark.',
    landingPageGoIn: 'Go in',
    landingPageAudioCallOut: 'This display is for testing the local microphone activation. No data is transmitted anywhere.',
    landingPageSetAudioDevices: 'Set Audio Devices',
    landingPageRisk: 'Risk of cumbersome handling with your current browser.',
    landingPageWeRecommend: 'We recommend:',
    landingPageRiskCallOutPart1: 'Some Browsers can be tricky as they are not all following the same standards and sometimes generate incompatibilities.',
    landingPageRiskCallOutPart2: 'For this reason, we cannot recommend the use of Safari or Firefox at the moment.',

    // waitingArea
    waitingAreaHelloVisitor: 'Hello',
    waitingAreaWaitBriefly: 'Please wait briefly:',
    waitingAreaHostInformed: 'your host has just been informed of your arrival.',
    waitingAreaNoOneGreet: 'No one seems to have come to greet you:',
    waitingAreaClickAgain: 'Please click the button with the bell below to send a further notification of your presence.',
    waitingAreaNotifyHost: 'Notify Host',

    // invalid invitation link
    insertedLinkIncorrect: 'The inserted link appears to be incorrect or incomplete!',
    verifyAccuracyOfInvitationLink: 'Please verify its accuracy and copy it back into a browser tab.',
    invalidInvitationProblemPersists: 'If this problem persists, contact our support service ',
    supportServiceHere: 'here',

    // Campus Settings
    changeColorActionTitle: 'Change color',
    changeLogoActionTitle: 'Change logo',
    changeImageActionTitle: 'Change image',
    changeImageCalloutTitle: 'This image will appear in the background of your visitor entrance. The file size must be up to 2 MB.',
    visitorEntranceYTTitle: 'Video for the waiting room',
    instructionToSetVideoAtVisitorEntrance:
        'Set a video here that guests can watch when waiting for a person in the guest reception. The video appears in the guest reception unless a different video is set when creating an access authorization.',
    pasteYTVideo: 'Paste a YouTube video link here',
    changeYTVideo: 'Change video',
    selectYTVideo: 'Select video',
    changeColorCalloutTitle: 'This will determine your branding color and will be displayed especially in the header of your CAMPUS',
    changeLogoCalloutTitle:
        'The logo will appear on your top bar, in your waiting area, and when inviting colleagues to your CAMPUS. The maximum size shoud be 250 x 100 and 2 MB',
    ownershipTitle: 'Ownership',
    transferOwnershipActionTitle: 'Transfer ownership',
    transferActionTitle: 'Transfer ownership',
    transferOwnershipError:
        'Some error occurred during the process of transferring ownership. Please try to transfer ownership again and if the error is repeated contact the responsible person.',
    transferOwnerShipDescriptionTitle:
        'When transferring ownership you will no longer be the owner and, if applicable, the creditor of this CAMPUS. Please be aware of this before transferring!',
    currentManagersListTitle: 'List of current managers',
    searchManagersPlaceholder: 'Search managers',
    managerDetailsListTitle: 'Details of managers',
    transferOwnershipWarningTitle1: 'Clicking confirm changes ownership rights to ',
    transferOwnershipWarningTitle2: ' and you will become a manager.',
    brandIdentityTitle: 'Brand identity',
    campusNameTitle: 'Campus name',
    campusColorTitle: 'Color',
    campusLogoTitle: 'Logo',
    saveCampusSettingsDescription: 'Clicking confirm will save any changes you just made on your CAMPUS settings.',
    accessMethodTitle: 'Access method',
    switchOnDescription: 'Switch on MS active directory sync for all CAMPUS members.',
    switchOffDescription: 'Everyone from your Microsoft organisation is asked to join this CAMPUS on sign-up.',
    accessMethodCallout1: 'You can link your CAMPUS to your Microsoft organisation.',
    accessMethodCallout2: 'This way everyone in your Microsoft organisation will be asked to join this CAMPUS on sign-up.',
    confirmDescription: 'Are you sure?',
    OwnershipTransferredActionTitle: 'Ownership transferred',
    signOutAndSignInError: 'Please sign out from the CAMPUS and then sign in again to implement the changes.',
    errorOccurred: 'Error occurred',
    removeOrganizationButton: 'Remove organisation',
    removeOrganizationChoiceAccept: 'Yes, I am sure',
    removeOrganizationChoiceRefuse: 'No, I do not want to remove it',
    removeOrganizationQuestion: 'Are you sure you want to remove [org]?',
    removeOrganizationExplanation: 'This means that all members, areas, rooms and documents created on the Campus of this organisation will be lost.',
    removeOrganizationModalTitle: 'Remove organisation',
    isSavingChanges: 'Saving changes...',

    // Auth
    loginPromptTitle: 'Identification, please!',
    loginPromptDescription: 'Sign in with your company-issued Microsoft account...',

    // Notifications
    meetNotificationActionTitle: 'Meet',
    inviteNotificationActionTitle: 'Invite',
    acceptNotificationActionTitle: 'Accept',
    answerNotificationTitle: 'Answer',
    doorKnockNotificationBody: 'Do you want to invite them in?',
    doorKnockConfirmationNotificationBody: 'Everyone in the room has been notified!',
    memoSentNotificationBody: 'The person has been notified',
    noUsersWarning: 'There is no one here to announce something to',
    showDetailsActionTitle: 'Show details',
    meetThemActionTitle: 'Meet them',
    visitorIsWaitingNotificationTitle: 'is at the door!',
    visitorIsWaitingNotificationBody: 'Do you want to meet them?',
    visitorFor: 'For: ',
    visitorHost: 'Host: ',
    visitorIsWith: ' is with ',
    visitorWithOthers: ' and others are with ',
    memoSentToNotificationActionTitle: 'Memo sent to ',
    somethingWentWrongNotificationActionTitle: 'Something went wrong',
    invitationSentNotificationActionTitle: 'Invitation sent!',
    inputSupportsMarkdownTitle: 'This input supports markdown (click to find out more)',
    inviteVisitorNotificationBody: 'Do you want to join them?',
    youHaveTheGuestMessage: 'You have a guest!',
    successMessage: 'Success!',
    inviteYouToMessage: 'invites you to',
    inviteYouToMessage1: 'invites you to his/her personal room',
    invitedYouToMessage: 'invited you to',
    invitedYouToMessage1: 'invited you to his/her personal room',
    inviteYouToMessage2: '',
    saysMessage: 'says:',
    saidMessage: 'said:',
    isAtYourDoorMessage: 'is at the door',
    wasAtYourDoorMessage: 'was at the door',
    someoneMessage: 'Someone',
    replyActionTitle: 'Reply',
    inviteThemInActionTitle: 'Invite them in',
    acceptInvitationActionTitle: 'Accept invitation',
    userAlreadyExists: 'User already exists',
    sendMemoToActionTitle: 'Send memo to',

    // Grouped Notifications
    closeAllMemosAndInvitations: 'Close all memos and invitations',
    closeAllMemos: 'Close all memos',
    closeAllInvitations: 'Close all invitations',
    closeAllVisitorNotifications: 'Close all visitor notifications',
    closeAllMessages: 'Close all messages',
    closeAllMessageDialogSubText1: 'If you close this window, you will lose all your ',
    onlyMemos: 'memos',
    onlyInvitations: 'invitations',
    memosAndInvitations: 'memos and invitations',
    onlyVisitorNotifications: 'visitor notifications',
    onlyMessages: 'messages',
    closeAllMessageDialogSubText2: 'Are you sure?',
    closeAllMessageDialogNo: 'No',
    closeAllMessageDialogYes: 'Yes',
    seekingAttention: 'is seeking your attention!',
    wasSeekingAttention: 'was seeking your attention!',
    thereAreVisitors: 'Someone is at the visitor entrance!',
    youHave: 'You have ',
    unreadMemos: 'unread memo(s) ',
    notificationDescriptionAnd: 'and ',
    ignoredInvitations: 'ignored invitation(s).',
    visitorsWaiting: ' visitors waiting.',
    viewAll: 'View all',
    hideAll: 'Hide all',
    closeAll: 'Close all',

    //User Actions
    inviteUserToRoomActionTitle: 'Invite to your room',
    sendUserMemoActionTitle: 'Send memo',
    kickOutVisitorActionTitle: 'Remove visitor from CAMPUS',

    // Sidebar
    personalRoomsSectionTitle: 'Personal rooms',
    commonRoomsSectionTitle: 'Rooms',
    commonRoomsSectionEmptyNoticeTitle: 'No rooms on this floor',
    commonRoomsSectionEmptyNotice: 'You can add rooms below.',

    // Member Invites
    inviteMemberLabelTitle: 'to this organization',
    inviteAnyoneLabelTitle: 'from outside',
    inviteMemberLabel: 'Invite member',
    inviteMemberPlaceholder: 'Email address',
    inviteMemberErrorMessage: 'Invalid email address',
    InviteRoleErrorMessage: ' is not valid, please select user or manager',
    inviteLanguageErrorMessage: ' is not valid, please select English or Deutsch',
    inviteMemberDescription1:
        'This is a single member invite. After sending the invite the person will be notified via email and will appear on the members list.',
    inviteMemberDescription2:
        'Use a .csv file to upload multiple members at one time. After transfering the invite list the people will be alerted via email and will appear on the members list.',
    ImportCSVFileTitle1: 'Import CSV File',
    ImportCSVFileTitle2: 'Revising invitations',
    ImportCSVFileTitle3: 'Inviting...',
    ImportCSVFilePlaceholder: 'Drag and drop a .csv file here, or click to select a file.',
    downloadCSVTemplateFileLabel: 'Download .CSV template file',
    changeFileActionTitle: 'Change file',
    inviteThesePeopleActionTitle: 'Invite these people',
    inviteThesePeopleConfirmDescription: 'Are you sure you want to invite these people?',
    emailTitleLabel: 'Email address',
    emailTitlePlaceholder: 'Type in a work email address',
    roleTitleLabel: 'Role',
    languageTitleLabel: 'Language',
    rolePlaceHolder: 'Choose a role',
    inviteSingleUserTitle: 'Add a single member',
    inviteMultipleUserTitle: 'Add multiple members',
    bulkInviteDescription1: 'A bulk invitation is generated via a ',
    bulkInviteDescription2: ' file containing the email addresses of all invited members. The new member will automatically appear on the members list.',
    sendInviteButtonTitle: 'Send invite',
    sendInvitesButtonTitle: 'Send invites',
    openBulkInviteButtonTitle: 'Open bulk invite',
    confirmBulkInviteQuestion1: 'Are you sure you want to send these member invites?',
    confirmBulkInviteQuestion2: 'Are you sure you want to send this member invite?',
    bulkInviteStep1Title: 'Step one:',
    bulkInviteStep2Title: 'Step two:',
    bulkInviteStep1Description: 'Download the template file and fill it in with the email addresses of the member you intend to add to your CAMPUS.',
    bulkInviteStep2Description: 'Once completed, upload it to the box below to proceed with the bulk invitation.',
    invitationAlreadySent: 'Invitation already sent',
    successfullyInvited: 'Successfully invited!',
    inactiveAccountInvited: 'Inactive account',
    invitedSponsorTooltipYourList: 'The person is included in your billing list',
    invitedSponsorTooltipOtherList: 'The person is on another billing list',

    // Invitations
    createInvitationTitle: 'Create new invitation',
    createInvitationButtonTitle: 'Create new invitation',
    inviteMemberActionTitle: 'Invite member',
    bulkInviteActionTitle: 'Bulk invite',
    resendInvitationActionTitle: 'Resend invitation?',
    resendInvitationActionDescription: 'Are you sure you want to resend the invitation to ',
    reviewInvitationTitle: 'Review invitations',
    reviewInvitationDescription1: 'One or more people could not be invited as they have no active account.',
    reviewInvitationDescription2: 'However, their invitations can be reworked at any time by filtering the table by Inactive.',
    reviewInvitationClose: 'Close',
    deleteInvitationActionTitle: 'Delete invitation',
    deleteInvitationActionTitle2: 'Delete invitation',
    deleteInvitationActionDescription: 'Are you sure you want to delete this user?',
    invitationsManagerTitle: 'Invitations manager',
    invitationsTitle: 'Invitations',
    waitingVisitorsTitle: 'Waiting visitors',
    filterInvitationsPlaceholder: 'Filter invitations',
    upcomingInvitationsTitle: 'Upcoming invitations',
    recurringInvitationsTitle: 'Recurring invitations',
    showExpiredInvitationsActionTitle: 'Show expired',
    noVisitorsDescriptionTitle: 'There are no visitors at the moment',
    areaTitle: 'Area',
    roomTitle: 'Room',
    hostsTitle: 'Host(s)',
    welcomeMessageTitle: 'Welcome message',
    missingWelcomeMessageTitle: 'No welcome message entered.',
    validityTitle: 'Validity',
    validTitle: 'Valid',
    validFromTitle: 'valid from',
    onTitle: 'On',
    selectedHostTitle: 'Selected host',
    hostSelectorDescription: 'Hosts will be notified of the arrival of a visitor.',
    hostSelectorPlaceholder: 'Start typing names to add other hosts',
    invitationValidityCallout: 'Single invitations are valid for the date specified below while recurring invitations are repeated on a specified frequency.',
    invitationTypeCallout:
        'In the case of a direct link, visitors will be able to enter directly a specified room, whereas in the case of a pick up link, visitors will have to be picked up by a host in the waiting area.',
    invitationDeleteConfirm: 'Are you sure you want to delete this invitation link?',
    eventTypeSingle: 'Single',
    eventTypeRecurring: 'Recurring',
    InvitationTypeDirect: 'Direct',
    InvitationTypePickup: 'Pick up',
    RecurringTypeEveryDay: 'Every day',
    RecurringTypeEveryWeek: 'Every week',
    invitationDeleteConfirm2: 'Are you sure you want to delete',
    copyFailedActionTitle: 'Copy failed',
    copyActionTitle: 'Copy',
    copiedActionTitle: 'Copied!',
    manageAccessTitles: 'Manage access',
    msEnabledTitle: 'MS ACTIVE DIRECTORY: enabled',
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingTitleText: 'Loading',
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
    invitationSendButtonText: 'Submit',
    invitationEmailSubject: 'You have an access invitation to the Campus from {orgName}',
    invitationEmailBody: `Title: {invName}%0D%0A
    %0D%0AValidity: {frequency} from {validFromTime} to {validToTime}%0D%0A
    %0D%0AAccess link: {invitationLink}%0D%0A
    %0D%0A
    %0D%0A===================================================%0D%0A
    %0D%0A
    %0D%0ALearn more about ivCAMPUS: https://ivicos.eu%0D%0A
    `,
    createAndContinue: 'Create and continue',
    updateInviationTitle: 'Your invitation is ready and looks like this:',
    editCustomUrlTitle: 'Link preview',
    editCustomUrl: 'Customize ending',
    customUrlCallout:
        'Here, the ending of the invitation’s link can be changed as desired. For example, the title of an event or the name of a person to be invited can be used. Please do not use any special characters.',
    calendarIntegrationTitle: 'You can also choose between the following options:',
    calendarIntegrationButton: 'Add to calendar and/or download .ics file',
    invitationToEmailButton: 'Send to e-mail address(es)',
    downloadInvitation: 'Download .ics file',

    // Custom Url Modal
    customUrlModalTitle: 'Modify link appearance',
    customUrlModalDescription1:
        'Here you can change the appearance of your invitation link as you wish, e.g. with the title of the event, or with the name of the person you wish to invite. Closing or pressing discard will still save the invitation with the default link.',
    customUrlModalDescription2: 'Please don’t use special characters.',
    customUrlModalErrorNoSpecialCharacters: 'Special characters cannot be used!',
    customUrlModalErrorMaximumLimit: 'The maximum character limit is 50!',
    customUrlModalErrorMinimumLimit: 'The minimum character limit is 1!',
    customUrlModalErrorCustomUrlExists: 'This link already exists!',
    customUrlModalLinkAppearance: 'Link preview:',
    customUrlModalDiscard: 'Discard',
    customUrlModalSaveAndCopy: 'Save and copy',

    // Customized video url
    customizedVideoLabel: 'Use default video for the waiting room',
    customizedVideoCallOutPart1: 'This video is offered to guests for viewing when waiting for a pick-up in the guest reception.',
    customizedVideoCallOutPart2: 'The video replaces the video set as default in the CAMPUS settings.',
    customizedVideoOn: 'On',
    customizedVideoOff: 'Off',
    customizedVideoPasteLink: 'Paste YouTube link',

    // Visitors
    visitorTabTitle: 'Visitors',
    visitorEntranceTitle: 'Visitor entrance',
    farewellMessageTitle: 'Farewell message',
    farewellMessageCallout:
        'All CAMPUS visitors can be manually dismissed with a simple click while hovering on their badge. To accompany their departure, it is possible to choose a farewell message that will appear once they have left.',
    visitorLinkTitle: 'Your invitation link',
    visitorLinkDescription: 'Share this link to invite people to this floor. You will get notified when you have a visitor and they will show up below.',
    copyButtonLabel: 'Copy invitation link to clipboard',
    copyButtonSuccessLabel: 'Link copied!',
    copyButtonErrorLabel: 'Error :(',
    letVisitorInsideActionTitle: 'Let them inside',
    letVisitorInActionTitle: 'Let them In',
    meetVisitorActionTitle: 'Meet',
    dialogYourInvitationSent: 'Your invitation has been sent! It might take up to 5 minutes to receive it.',
    dialogPersonNotAddedToBillingList1: 'Note: The person you invited has an active account and will therefore not be added to ',
    dialogPersonNotAddedToBillingList2: '´s billing list.',
    dialogPersonDoesNotHaveActiveAccountTitle: 'Inactive account',
    dialogPersonDoesNotHaveActiveAccount1: 'The person you want to invite does not have an active user account.',
    dialogPersonDoesNotHaveActiveAccount2: 'Would you like to add this person to your organization?',
    dialogWeDidNotSendInvitationTitle: 'Invitation was not sent!',
    dialogPersonDoesNotHaveActiveAccount3: 'We have not sent an invitation because the user is not yet registered on ivCAMPUS.',
    dialogPersonDoesNotHaveActiveAccount4:
        'Please ask this person, who should not be assigned to your organisation, to create their own CAMPUS for their organisation. As soon as they are registered on the platform, they can be invited to your campus.',
    dialogPersonDoesNotHaveActiveAccountNo: 'No',
    dialogPersonDoesNotHaveActiveAccountYes: 'Yes',
    dialogIsAlreadyAMember: ' is already a member',
    notActiveMessageDaily: 'Your invitation with the title "{eventName}" is valid everyday from {timeFrom} to {timeTo}.',
    notActiveMessageWeekly: 'Your invitation with the title "{eventName}" is valid every week from {dayFrom} at {timeFrom} to {dayTo} at {timeTo}.',
    notActiveMessageSingle: 'Your invitation with the title "{eventName}" is valid from {completeDateFrom} to {completeDateTo}.',
    youAreTalkingTo: 'You are talking to',
    directVisitorToRoom: 'Direct visitor to room',
    thanksForVisitMessage: 'Thank you for your visit!',
    VisitorExitDescription1: 'Your visit was enabled by ivicos CAMPUS.',
    VisitorExitDescription2: 'Learn more at',
    VisitorExitDescription3: 'ivicos.eu',
    invitationLinkNotValid: 'The visitor entrance is currently closed. Therefore, the link does not lead any further.',
    errorInButton: 'Error',
    backToAreaButton: 'Back to area',

    // Area Actions / Navigator
    addAreaActionTitle: 'Add area',
    editAreaActionTitle: 'Edit Area',
    deleteAreaActionTitle: 'Delete Area',
    searchAreasPlaceholder: 'Search areas',
    createAreaTitle: 'Create new area',
    enterAreaNameLabel: 'Area name',
    enterAreaNamePlaceholder: 'Type new area name',
    deleteAreaDescription: 'Are you sure you want to delete',
    areaLeaveConfirmDescription: 'Are you sure you want to leave this area?',
    panelTitle: 'Navigator',
    showMoreOptionsTooltip: 'Show more options',

    // Area Actions / Search Person
    crossAreaSearchPersonIconTitle: 'Search person',
    crossAreaSearchPersonPanelHeaderTitle: 'Search person',
    crossAreaSearchPersonPanelCloseButton: 'Close',
    crossAreaSearchPersonSearchBarPlaceholder: 'Search',
    crossAreaSearchPersonThisArea: 'this area',
    crossAreaSearchPersonOtherArea: 'other area (this campus)',
    crossAreaSearchPersonDashboard: 'lobby',
    crossAreaSearchPersonNotFound: 'No results were found for the entered search term',

    // Room Actions
    enterRoomActionTitle: 'Enter room',
    knockOnDoorActionTitle: 'Knock on door',
    editRoomActionTitle: 'Edit room',
    sendMemoIntoRoomActionTitle: 'Send memo into room',
    roomSettingsActionTitle: 'Room setup',
    createNewRoomActionTitle: 'Create new room',
    createNewRoomActionLoadingTitle: 'Creating new room...',
    deleteRoomActionTitle: 'Archive room',
    deleteRoomActionLoadingTitle: 'Archiving...',
    deleteRoomActionConfirmationTitle: 'Room archived.',
    NoRoomAvailableDescription: 'No room is available in this area',
    memoPlaceholder: 'Type your message...',
    mobileDescription1: 'Scan this code with your phone',
    mobileDescription2: 'To join the room',

    // Room settings
    roomSettingsPanelTitle: 'Room setup',
    roomNameInputLabel: 'Room name',
    roomNameInputPlaceholder: 'Untitled Room',
    iconPickerLabel: 'Room Icon',
    privacySectionLabel: 'Privacy',
    openToVisitorsCheckBoxLabel: 'Open to external visitors',
    openToVisitorsCheckBoxDescription:
        'Visitors invited by CAMPUS members may enter this room. This room is marked separately with this symbol. However, only when visitors are on Campus.',
    confirmDeletePromptLabel: 'Are you sure you want to delete this room from CAMPUS?',
    audioOnlyCheckBoxLabel: 'Disable video in this room',
    audioOnlyCheckBoxDescription: 'Conversations in this room are audio only if this option is set',
    whitelistSelectorLabel: 'Whitelist',
    whitelistSelectorPlaceholder: 'Start typing names to add users to the whitelist...',
    whitelistSelectorDescription: '',
    toggleWhitelistLabel: 'Allow access for only selected users',
    toggleWhitelistDescription: '',
    dismissAction: 'dismiss',
    deleteAction: 'delete',
    noneAction: 'none',
    deletionAction: 'deletion',
    updateAction: 'update',
    deleteQuestion1: 'Are you sure?',
    deleteQuestion2: 'Discard unsaved changes?',
    deleteQuestion3: 'Archived rooms will become unavailable to all users...',
    deleteQuestion4: 'You have made changes to this room that have not been saved yet... do you want to discard them?',
    setTopicTitle: 'Set topic',
    setATopicDialogBoxTitle: 'Set a topic ',

    //Call view
    deviceSettingsActionTitle: 'Device settings',
    toggleVideoActionTitle: 'Toggle camera',
    toggleAudioActionTitle: 'Toggle microphone',
    toggleAttachment: 'Toggle Attachments',
    safariNotSupported: 'This feature is currently not supported in safari',
    toggleTileViewActionTitle: 'Toggle tile view',
    shareScreenActionTitle: 'Share screen',
    shareScreenActionActiveTitle: 'Stop sharing screen',
    toggleEncryptionActionTitle: 'Toggle encryption (experimental)',
    toggleEncryptionActionActiveTitle: 'Encryption is enabled (BETA)',
    raiseHandActionTitle: 'Raise hand',
    unraiseHandActionTitle: 'Tap "Done" to lower your hand',
    unraiseHandActionButtonTitle: 'Done',
    toggleBackgroundDialogActionTitle: 'Set my video background',
    leaveRoomActionTitle: 'Back to personal room',
    toggleStatsActionTitle: 'Toggle Virtual Moderator',
    encryptionIsEnabledLabel: 'End-to-end encrypted',
    connectedToLabel1: 'Securely connected to',
    connectedToLabel2: 'CAMPUS platform',
    connectedToLabel3: 'Powered by IONOS Cloud ',
    // eslint-disable-next-line quotes
    personalRoomTitle: "'s personal room",
    // eslint-disable-next-line quotes
    personalRoomHelper: "'s personal",
    // eslint-disable-next-line quotes
    personalRoomTitleForNameEndingWithS: "s's personal room",
    unknownPersonalRoomTitle: 'A personal room',
    callLoadingScreenLabel: 'Connecting to secure server...',
    callLoadingScreenDescription: 'Please wait, while we establish a connection with one of our secure servers in Europe.',
    changeCallAudioAndVideo: 'You can change your audio and video settings here.',
    callRetryMessage: 'If this takes longer than expected retry',
    PleaseStandByMessage: 'Please stand by.',
    announcingArrival: 'We are announcing your arrival...',
    retryActionTitle: 'Retry',
    safariRoomCallDescription:
        'Safari disallows cross-site tracking by default by disabling third-party cookies and cross-site localStorage. So you would not be able to access etherpad unless you disable it manually by following below mentioned steps.',
    safariRoomCallDescriptionStep1:
        '1. Open Safari Preferences, by clicking on the `Safari` menu on the left of the menu bar, and clicking on`Preferences`, or by pressing `⌘,`.',
    safariRoomCallDescriptionStep2: '2. Click on the `Privacy` tab.',
    safariRoomCallDescriptionStep3: '3. Uncheck the `Prevent cross-site tracking` check box.',
    waitingAreaTitle: 'Visitor entrance',
    videoCallTitle: 'Video call',
    setATopicActionTitle: 'Set topic',
    alertMessageWhileScreenShareIsActive: 'Stop screen sharing to return to your personal room',
    callActionButtonSelectBackground: 'Select background',

    // Device settings
    audioSettingsTitle: 'Audio settings',
    videoSettingsTitle: 'Video settings',
    audioInputDeviceTitle: 'Audio input device',
    audioOutputDevice: 'Audio output device',
    videoDeviceTitle: 'Video device',
    selectDevicePlaceholder: 'Select device',

    // Portal
    availableAreasTitle: 'Areas',
    manageAccessTitle: 'Members',
    campusSettingsTitle: 'CAMPUS settings',
    billingTitle: 'Billing',
    addAreaToTabBarActionTitle: 'Display area on tab bar',
    // eslint-disable-next-line quotes
    removeAreaFromTabBarActionTitle: "Don't display area on tab bar",
    changeLogTitle: 'Changelog',
    areaOpenToEveryoneLabel: 'Open to all members',

    // Members
    filterMembersPlaceholder: 'Filter members',
    emailColumnTitle: 'Email',
    nameColumnTitle: 'Name',
    roleColumnTitle: 'Role',
    pendingInviteTitle: 'Invite pending...',

    // Roles
    userRoleTitle: 'user',
    managerRoleTitle: 'manager',
    userRoleTitleInCap: 'User',
    managerRoleTitleInCap: 'Manager',
    ownerRoleTitle: 'Owner',
    inviteeRoleTitle: 'Invited',
    inactiveInviteeRoleTitle: 'Inactive',

    // Language
    englishLanguageTitle: 'English',
    deutschLanguageTitle: 'Deutsch',

    //Alerts
    generalAlertTitle: 'Warning',
    webSocketAlert: 'We are unable to connect you to the web socket. Please try again.',
    connectionTimedOut: 'Your connection timed out.',
    safariDetection: 'Sadly CAMPUS does not support the Safari browser at the moment :/ We recommend using Edge or Chrome',
    mobileDetection: 'Sadly CAMPUS does not support mobile view at the moment :/ We recommend using a computer',
    invalidDataAlert: 'Invalid data',
    yeetAlert: 'yeet',
    browserNotSupportAlert: 'This browser does not support desktop notifications.',
    errorConnectingAlert: 'There was an error with your connection... press ok to reconnect.',
    connectionLostMessage: 'Connection lost!',
    lostConnectionMessage: 'You seem to have lost connection to the internet.',
    clickToReconnectMessage: 'Please click the button below after you have reestablished a connection.',
    firefoxAlert: 'Firefox Screen Sharing: Please click anywhere on the video frame below before attempting to share your screen',
    reconnectToMicrosoft: 'Reconnecting to Microsoft Services... ',
    trialPeriodExpiredTitle: 'Trial Period Expired',
    trialPeriodExpiredContent: 'The trial period of [org] has ended.',
    trialPeriodExpiredContactSalesTeam1: 'Your Campus is currently not accessible anymore. Please contact our team at ',
    trialPeriodExpiredContactSalesTeam2: ' now and they’ll be happy to provide access to your rooms and areas again.',
    trialPeriodExpiredContactSalesTeam3: 'Alternatively, you can click the button below and it will open your standard email application.',
    trialPeriodExpiredUserContent:
        'The trial period of [org] has ended. No further agreement has been made for your organization yet. Please contact the sales department. They will be happy to assist you.',
    trialPeriodExpiredChoiceTitle: 'Choose an action',
    trialPeriodExpiredChoiceAccept: 'Make a subscription',
    trialPeriodExpiredChoiceRefuse: 'Remove current Campus and start a new trial period',
    makeSubscriptionTitle: 'Make a subscription',
    makeSubscriptionContent: 'To make a subscription, we need some additional details',
    subscriptionFieldCompanyName: 'Company name',
    subscriptionFieldPostalCode: 'Postal code',
    subscriptionFieldAddress: 'Address',
    subscriptionFieldAddressPlaceholder: 'Street and Number',
    subscriptionFieldCityPlaceholder: 'City',
    subscriptionFieldCity: 'Town/City',
    subscriptionFieldCountryCode: 'Country code',
    subscriptionFieldVatNumber: 'VAT number',
    trialPeriodAboutToExpireTitle: 'The trial period will end soon!',
    trialPeriodAboutToExpireDaysLeft: 'There are [count] days left until the end of the trial period, after which [org] will be removed',
    trialPeriodAboutToExpireQuestion: 'Would you like to continue using this Campus? Please get in touch with sales now.',
    billingActiveDirectTitle: 'Usage agreement with ivicos GmbH',
    billingActiveDirectDescription: 'This CAMPUS is directly invoiced to ivicos GmbH. If you have any questions, please contact our team at ',
    billingActiveDirectAlternative: 'Alternatively, you can click the button below and it will open your default email application.',

    // Tooltips
    announcementTitle: 'Announce something...',
    areaSwitchTitle: 'Back to the Lobby / Display available areas',
    togglePanelTitle: 'Toggle panel',
    backToDashboardTitle: 'Back to the lobby',
    openForVisitorsTitle: 'The room is open to visitors',
    roomOptionsTitle: 'Room options',
    noAccessToRoom: 'A special access authorization is required for this room',

    // Attachments
    toggleAttachmentsActionTitle: 'Toggle file list',
    attachmentPanelTitle: 'Attachments',
    videoOwnerTransferModalTitle: 'Video Playback Control',
    videoOwnerTransferModalSentence1: 'You are about to transfer control of video playback to',
    videoOwnerTransferModalSentence2: 'Are you sure you want to continue?',
    videoOwnerTransferModalCancelButtonLabel: 'Cancel',
    videoOwnerTransferModalTransferButtonLabel: 'Transfer',
    videoOwnershipTransferredToLocalUserModalSentence1Part1: 'You have been elected controller of the video by ',
    videoOwnershipTransferredToLocalUserModalSentence1Part2: ' and are now responsible for its playback. ',
    videoOwnershipTransferredToLocalUserModalSentence2: 'If you leave the room for any other reason, you can appoint another person to control video playback.',
    videoOwnershipTransferredToLocalUserModalOkButtonLabel: 'Ok',
    videoControlPanelTitle: 'Video Playing',
    videoControlPanelPlaybackControlledByTitle: 'Controlled By',
    videoControlPanelTransferPlaybackControlTitle: 'Transfer video control playback to',
    pinnedAttachmentPanelTitle: 'Pinned Attachment',
    createNewAttachmentActionTitle: 'Create new file',
    filterAttachmentsPlaceholder: 'Filter files...',
    savedItemTitle: 'Saved items',
    noAttachmentsWarning: 'No files in this room, yet.',
    chooseAttachmentTypeTitle: 'Choose tool type...',
    linkTypeTitle: 'Link',
    collaboardTypeTitle: 'Collaboard',
    collaboardTypeDescription: 'Real-time collaboration tool',
    collaboardLinkAttachmentModalBoxTitle: 'Create new Collaboard project attachment',
    colleboardLinkAttachmentModalBoxSubText: 'Please enter (or paste) a link to a Collaboard project',
    etherpadTypeTitle: 'Etherpad',
    excalidrawTypeTitle: 'Excalidraw',
    youtubeLinkTypeTitle: 'YouTube',
    youtubeLinkTypeDescription: 'Watch a video together',
    tldrawTypeTitle: 'Tldraw',
    linkTypeDescription: 'Add link to a website, tool or file',
    etherpadTypeDescription: 'Real-time document editing tool',
    tldrawTypeDescription: 'Real-time whiteboard tool',
    enterUrlActionTitle: 'Please enter an Internet-Link (including https://)',
    deleteAttachmentConfirmationTitle: 'Are you sure you want to delete the link to this file?',
    editAttachmentTitle: 'Edit file description',
    noDescriptionProvidedWarning: 'No description provided',
    createdAtTitle: 'Created',
    justNowTitle: 'just now',
    oneMinutePassedTitle: '1 minute ago',
    minutesPassedTitle: '{quantity} minutes ago',
    oneHourPassedTitle: '1 hour ago',
    hoursPassedTitle: '{quantity} hours ago',
    yesterdayTitle: 'yesterday',
    daysPassedTitle: '{quantity} days ago',
    weeksPassedTitle: '{quantity} weeks ago',
    monthsPassedTitle: '{quantity} months ago',
    aYearPassedTitle: 'a year ago',
    yearsPassedTitle: '{quantity} years ago',
    giveAttachmentNameTitle: 'Give your file a title',
    giveAttachmentDescriptionTitle: 'Add a description to your file',
    linkAttachmentModalBoxTitle: 'Create new link attachment',
    linkAttachmentModalBoxSubText: 'Please enter (or paste) a link to a webpage that can be opened in a new browser tab',
    youtubeLinkAttachmentModalBoxTitle: 'Create a new YouTube video attachment',
    youtubeLinkAttachmentModalBoxSubText: 'Paste below the link to any YouTube video',
    youtubeLinkAttachmentModalVideoPinText: 'Pin video to the room',
    youtubeLinkAttachmentModalVideoPinUpperText:
        'The video can be pinned to the room, so that it is prominently displayed at the top of the attachments panel.',
    youtubeVideoPlaying: 'Video playback',
    youtubePlaybackControl: 'Controlled by',
    youtubePlaybackTransfer: 'Transfer video control playback to',
    youtubePinnedVideo: 'Pinned video',

    // Speaker stats
    speakerStatsTitle: 'Virtual Moderator:',
    totalTalkTimeTitle: 'Total talk time',
    personalTalkTimeToggle: 'Show me my personal talk time.',
    // eslint-disable-next-line quotes
    talkingTooMuchToggle: "Tell me when I'm talking too much.",
    experimentalLabelTitle: 'Experimental',
    noDataTitle: 'No data yet',
    talkingMoreDescription: 'FYI - You are talking more than everyone else.',
    talkingMoreDescription2: 'Would it now be time to give the floor to someone else?',
    startButtonLabel: 'Start',
    roundTableTitle: 'Round table',
    applyTimer: 'Apply timer',
    startRoundTableModeTitle: 'Start round table mode',
    stopRoundTableModeTitle: 'Stop round table mode',
    roundTableModeDialogBoxTitle: 'Round Table Mode',
    youAreOnTitle: 'You are on!',
    youAreTheLastSpeakerTitle: 'You are the last speaker',
    clickNextWhenYouAreDoneTitle: 'Click "next" when you are done',
    nextActionTitle: 'Next',
    nextActionTitle2: 'Next',
    isOnTitle: 'is on!',
    minutesLabel: 'Minutes',
    addUserToRoundTableButtonLabel: 'Include',
    removeUserFromRoundTableButtonLabel: 'Remove',

    // General Time Words
    from: 'From',
    at: 'at',
    to: 'to',
    until: 'until',
    everyWeekOn: 'Every week on',
    everyday: 'Everyday',
    weekly: 'weekly',
    daily: 'daily',
    noRepeat: 'no repeat',

    // Months
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',

    // MonthsInShortForm
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may2: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',

    // Weekdays
    monday: 'Mondays',
    tuesday: 'Tuesdays',
    wednesday: 'Wednesdays',
    thursday: 'Thursdays',
    friday: 'Fridays',
    saturday: 'Saturdays',
    sunday: 'Sundays',

    // ImagePicker
    uploadNewImageTitle: 'Upload a new image',
    uploadNewImageActionTitle: 'Upload new image',
    recentlyUploadedImages: 'Recently uploaded images',
    uploadActionTitle: 'Upload',
    clickToUploadImage: 'Click to upload image',
    deleteImageDescription: 'Are you sure you want to delete this image?',
    imageTitle: 'Image',
    uploadCap: 'The size of the image must not exceed 2 megabytes',
    uploadCapError: 'The size of the image you are trying to upload is larger than 2 megabytes',
    onImagePickerModalClose: 'Confirm and close',

    // OverlaySelector
    videoOverlayLabel: 'Video overlay',
    overlayLabel: 'Overlay',
    backgroundLabel: 'Background',
    checkboxLabel: 'Use transparency of image',

    // Availability
    available: 'Available',
    away: 'Away',
    beRightBack: 'Be right back!',
    doNotDisturb: 'Do Not Disturb',
    busy: 'Busy',

    // RegistrationForm
    registrationFormLabel: 'Entrance to the CAMPUS',
    registrationFormPlaceholder: 'Please enter a name',
    registrationFormErrorMessage: 'Please enter a valid name',
    registrationFormInfoMessage: 'Please enter your name, nickname or a psydonym. This information is not saved in our system and is deleted after you leave.',

    // User Profile
    editNamePlaceholder: 'Type in your name',
    editNameErrorMessage: 'The minimum limit is 1 character',
    updateImageButtonLabel: 'Update image',
    editNameButtonLabel: 'Edit name',

    // OnboardingVideo
    onboardingVideoTitle: 'This short tour explains the most important features. ',

    // FlagsView
    goBackActionTitle: 'Go back',
    darkModeActionTitle: 'Use dark mode',
    enableThisFrag1: 'If you enable this flag, you will be able to use CAMPUS in dark mode',
    useJitsiEnv: 'Use the Jitsi staging environment',
    enableThisFrag2: 'If you enable this flag, you will not be able to talk to users who have not enabled this flag.',

    // App Errors
    logInTwiceTitle: 'You logged into the same area twice',
    logInTwiceDescription:
        'You were automatically disconnected in this tab, because you logged into the same area from somewhere else. You can only log into the same area with the same account from one tab.',
    sessionExpiredTitle: 'Your session expired.Your have been automatically logged out.',
    sessionExpiredDescription: 'Your have been automatically logged out',
    sessionExpiredReasonTitle: 'Here are reasons why this might happen:',
    sessionExpiredReason1: 'Your magic link was valid a longer time already and now expired due to security reason. A new login is required.',
    sessionExpiredReason2: 'You tried to use a bookmark to enter a personal room. This is not allowed due to security reason.',
    sessionExpiredReason3: 'You might have entered the Campus from another device.',
    sessionExpiredReconnect: 'Reconnect',
    resourceNotFoundTitle: 'The resource you have requested could not be found',
    resourceNotFoundDescription: 'You have been automatically logged out',
    unexpectedErrorOccuredTitle: 'An unexpected error has occured',
    unexpectedErrorOccuredDescription:
        'You were automatically disconnected because an unexpected error occured. Please contact your account manager or info@ivicos.eu',

    // loadingStrings
    loadingStringAreas: 'Loading areas...',
    loadingStringSettings: 'Loading settings...',
    loadingStringMembersList: 'Loading members list...',
    loadingStringInvitation: 'Loading invitation...',
    loadingStringWaitingAreas: 'Loading waiting area...',
    loadingStringBilling: 'Loading billing area...',

    // SetupAssistant
    setProfileImage: 'Set profile image',
    setOrgLogo: 'Set logo of your organisation',
    setOrgColor: 'Set color of your organisation',
    SetupAssistantLabelOpenPrimary: 'Complete your company profile!',
    SetupAssistantLabelOpenSecondary: 'There are still a few things that need to be done as a CAMPUS-manager before your CAMPUS is complete!',
    SetupAssistantLabelCompletedPrimary: 'Setup completed!',
    SetupAssistantLabelCompletedSecondary: 'You have completed your company profile set up!',
    completeLabel: 'complete',
    completedLabel: 'completed',
    openLabel: 'open',

    // OnboardingVideo
    introVideoTitle: 'Short onboarding video',
    tutorialConfirmationTitle: 'And now quick tips to familiarise yourself in your new office!',

    // Intro Steps
    areaIntroStep1: 'Welcome to this guided tutorial to your CAMPUS! Simply click next to start exploring. ',
    areaIntroStep2:
        'Here you can find the Areas tab and the Change Log tab. For CAMPUS managers, along with these you will find the Manage Access and CAMPUS Settings tabs.',
    areaIntroStep3: 'This is an area. Within areas you can create rooms. There can be several areas on the CAMPUS.',
    campusSettingIntroStep1: 'Here you can store your profile picture and choose individual settings',
    campusSettingIntroStep2: 'Here you can set your CAMPUS name, brand color and logo.',
    memberIntroStep1:
        'Invite members to your CAMPUS by typing in their email and clicking the invite button. You can cancel or resend invitations in the table below, as well as remove members who have already joined.',
    memberIntroStep2:
        'This table will show all users you have invited to your CAMPUS, whether they have accepted the invitation or not. Their name and profile picture will be displayed once they accept the invitation.',
    memberIntroStep3: 'Filter the table by email.',
    roomIntroStep1: 'This section contains all the information regarding your account at CAMPUS and lets you choose and set a personal profile image.',
    roomIntroStep2: 'Every member has their own personal room when they enter an area. Hover over their names to reveal the communication options.',
    roomIntroStep3:
        'Rooms are customisable and are perfect for meetings, workshops, or a space to chat. They have more features than your personal room, and can be deleted or archived as you please.',
    roomIntroStep4: 'Here you can invite visitors who are not part of your CAMPUS.',
    roomIntroStep5: 'Use these icons to adjust the video and call settings.',
    roomIntroStep6: 'These icons allow you to access CAMPUS video features, like speaker stats.',
    roomIntroStep7: 'You successfully completed the tutorial. Enjoy your stay on CAMPUS!',

    // Not Supported Browser warning
    notSupportedBrowserString1: 'is a browser application that does not require any software to be installed.',
    notSupportedBrowserString2: 'As /devices/ and /devices/ are frequently updated and sometimes cause incompatibilities, not all versions can be supported.',
    notSupportedBrowserString3: 'Please note that ivCAMPUS cannot be used with the /browser/ and /browser/ versions of /browser/ and /browser/.',
    notSupportedBrowserString4: 'We recommend using one of the following browsers:',
    notSupportedBrowserFooterText: 'For more information of ivCAMPUS please /visit our website /or contact us /here',
    downloadHere: 'Download here',

    // Setup complete page
    setupCompletePageTitle: 'Setup complete!',

    // Profile page
    profilePageTitle: 'Setup Your Profile',
    profilePageTextFieldPlaceholder: 'Type in your name and surname',

    // ivCAMPUS Setup page
    ivCAMPUSSetupPageTitle: 'CAMPUS Setup',
    nameLabel: 'Name',
    organisationNamePlaceholder: 'Company name',
    sizeOfTheOrganisationLabel: 'Size',
    sizeOfTheOrganisationPlaceholder: 'How many people are in your organisation?',
    locationsLabel: 'Locations',
    howManyLocationsPlaceholder: 'How many locations do you have?',
    ivCAMPUSSetupPageCheckBoxDescription1: 'I hereby confirm that I am setting up a CAMPUS instance for the purposes of',
    ivCAMPUSSetupPageCheckBoxDescription3: '',
    ivCAMPUSSetupPageCheckBoxDescription2: 'I hereby confirm that I am setting up a CAMPUS instance for the purposes of (company name).',
    moreThan500: 'more than 500',
    moreThan20: 'more than 20',
    enterOrgNameErrorMessage: 'Please enter a valid Organisation name',
    enterOrgSizeErrorMessage: 'Please select the size of the organisation',
    enterOrgLocationErrorMessage: 'Please select the amount of locations',

    // FindCampus page
    findCampusPageTitle: 'Log into organisation...',
    findCampusPageLabel: 'Jump to:',
    findCampusPagePlaceholder: 'Select CAMPUS',
    hideOption: 'Hide options',
    showOption: 'Show me other options',
    startSetupButtonTitle: 'Create a new CAMPUS...',
    startSetupButtonDescription: 'Create a virtual office for your organization ',
    seeAllInvitations: 'See all invitations',
    invitationTitle: 'You have been invited to join',
    noSponsorModalTitle: 'Access inactive',
    noSponsorModalWelcome: 'Welcome to ivCAMPUS.',
    noSponsorModalDescription: 'You now have the opportunity to experience the full capabilities of Campus. ',
    noSponsorModalTip: 'After registering, you can view the remaining time of your trial period in the "Billing" tab.',
    trialPeriodOverButConsider1: 'Your trial period is over.',
    trialPeriodOverButConsider2: 'However you can continue if you…',

    // General Button Text
    enter: 'Enter',
    login: 'Log In',
    accept: 'Accept',
    decline: 'Decline',
    acceptAndJoin: 'Accept & Join',
    loading: 'Loading...',
    somethingWentWrong: 'Something went wrong :(',
    continue: 'Continue',
    finish: 'Finish',
    okAction: 'Ok',

    // General Text
    here: 'here',
    orVisitOur: 'or visit our',
    helpPage: 'help page',
    empty: 'Empty',
    signedInAs: 'Signed in as ',
    errorHasOccured: 'Error has occured',
    talkingToMicrosoft: 'Talking to the Microsoft Identity Platform...',

    // Error messages
    min3: 'name should be minimum 3 character',
    max7: 'name should be maximum 72 character',
    minimumLimit: 'The minimum limit is 3 characters',

    // Login Page
    signInTitle: 'Access',
    emailPlaceHolder: 'Type in your work email address',
    emailButtonText: 'Receive magic link',
    emailErrorMessage: 'Please enter a valid email address!',
    loginPageDescription3: 'We will send an access-link to your email-address.',
    orOptionTitle: 'or',
    developedByTitle1: 'Developed by',
    developedByTitle2: '(c) ivicos GmbH, Germany',
    newToPassWordlessAcesss: 'New to passwordless access?',
    clickHereToKnowMore: 'Click here to know more.',

    // NewUserAwaitingConfirmation Page
    userConfirmationPageTitle: 'Please confirm access',
    userConfirmationPageDescription1: 'Check your email inbox (and your spam folder).',
    userConfirmationPageDescription2: 'In the e-mail you receive: ',
    userConfirmationPageDescription3: 'Click on the access button or copy the access link and paste it into this or any tab.',
    userConfirmationPageDescription4: 'Nothing received? ',
    userConfirmationPageDescription5: ' Re-enter your e-mail-address',
    userConfirmationPageDescription6: 'again.',

    // MagicLinkVerification
    processingLoadingText: 'Processing...',

    // NewUserAccessGranted
    accessGrantedTitle: 'Access granted!',
    accessGrantedDescription1: ' was confirmed.',
    accessGrantedDescription2: 'Please click',
    accessGrantedDescription3: 'if you are not automatically being redirected to CAMPUS.',

    // UserAccessGranted
    userAccessGrantedDescription1: 'Hello ',
    userAccessGrantedDescription2: '! Your access has been confirmed.',
    userAccessGrantedDescription3: 'if you are not automatically being directed to CAMPUS.',

    // NewUserSomethingWrong
    somethingWrongTitle: 'Something went wrong!',
    newUserSomethingWrongDescription1: 'An error occurred during the authentication process.',
    newUserSomethingWrongDescription2: 'To receive a new access link, please click',
    newUserSomethingWrongDescription3: 'If the error still occurs, contact us',

    // AccountCreationSuccessful
    accountCreationSuccessfulTitle1: 'An account for ',
    accountCreationSuccessfulTitle2: ' has been successfully created!',
    accountCreationSuccessfulDescription: 'Please check your e-mail inbox (also your spam folder) and click on the access link to complete your profile.',

    // AccountCreationSomethingWentWrong
    accountCreationSomethingWentWrongDescription1: 'The creation of a new account could not be completed.',
    accountCreationSomethingWentWrongDescription2: 'Please contact us',

    // LoadingStrings
    creatingAnAccountLoadingString: 'Creating an account...',
    waitingForResponseLoadingString: 'Waiting for response...',

    // PasswordlessAccessModal;
    passwordLessAccessModalTitle: 'What is passwordless access?',
    passwordLessAccessModalDescription1:
        'Passwordless authentication is a method of verifying a user’s identity without using passwords. This method provides immediate protection against the most dangerous and common cyberattacks.',
    passwordLessAccessModalDescription2:
        'Users are this way also protected from scam emails and text messages that attempt to steal their passwords, since there’s no password to be stolen.',
    passwordLessAccessModalDescription3: 'How does it work?',
    passwordLessAccessModalDescription4:
        'The method is simple. Instead of being asked for a password, users only need to enter their email address in the login box. They will receive an email with a link they click to access. For security reasons, such link is sent to users each time they attempt to log in.',
    passwordLessAccessModalDescription5:
        'In the past, people went to work and showed their employee ID to the gatekeeper. Today it works with a simple link to be continuously present on CAMPUS.'
};
